import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { StorageService } from '../../vabourlettis/authentication/storage.service';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { SubjectService } from '../services/subject.service';
import { FrameworkService } from '../services/framework.service';
import { AuthenticationService } from 'src/sites/vabourlettis/authentication/authentication.service';
import { EncryptionService } from '../services/encryption.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor, OnDestroy {
   [x: string]: any;

   private isNetworkStatusOnline: boolean = false;
   private isRefreshingToken: boolean = false;

   constructor(
      public storageService: StorageService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private authenticationService: AuthenticationService,
      private encryptionService: EncryptionService,
   ) {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
   }

   public ngOnDestroy(): void {
   }

   public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
      //this.frameworkService.logInfo(request.url, "INTERCEPTOR");

      //Handle system file request 
      if (request.url.includes("Q102200002")) //FvwbController/UploadFile 
      {
         return next.handle(request);
      }
      else {
         if (request.url.includes("Q201200001") || request.url.includes("Q202800013")) { //FvwbController/Synchronize, UserPublicController/SendVCardToUserAsync
            return next.handle(this.addTokenHeader(request, '')).pipe(
               map((event: HttpEvent<any>) => {
                  if (event instanceof HttpResponse && event.body !== null) {
                     if (this.isNetworkStatusOnline)
                        this.subjectService.IsQueryOnTimeoutSubject.next(0);
                  }
                  return event;
               }),
               timeout(240000),
               catchError(error => {
                  if (error.name === 'TimeoutError') {
                     if (this.isNetworkStatusOnline) {
                        //this.frameworkService.logInfo("TIMEOUT", "interceptor Q201200001 ERROR");
                        //if (this.signalRService != undefined) this.signalRService.closeHubConnection();
                        this.subjectService.IsQueryOnTimeoutSubject.next(1);
                     }
                  } else {
                     //this.frameworkService.logInfo(error, "interceptor Q201200001 ERROR");
                     //if (this.signalRService != undefined) this.signalRService.closeHubConnection();
                     this.subjectService.IsQueryOnTimeoutSubject.next(2);
                  }
                  throw error;
               })
            );
         }
         else {
            //Handle system file request 
            if (request.url.includes("/assets/") ||
               request.url.includes("/appsettings/") ||
               request.url.includes("/Setting/") ||
               request.url.includes("/C1022/")) { //C1022 = SystemController
               return next.handle(this.addTokenHeader(request, '')).pipe(
                  map((event: HttpEvent<any>) => {
                     if (event instanceof HttpResponse && event.body !== null) {
                        this.subjectService.IsQueryOnTimeoutSubject.next(0);
                     }
                     return event;
                  }),
                  timeout(9000), // Set a timeout of 9 seconds
                  catchError(error => {
                     if (error.name === 'TimeoutError') {
                        //this.frameworkService.logInfo("TIMEOUT", "interceptor query1 ERROR");
                        //if (this.signalRService != undefined) this.signalRService.closeHubConnection();
                        this.subjectService.IsQueryOnTimeoutSubject.next(1);
                     } else {
                        //this.frameworkService.logInfo(error, "interceptor query1 ERROR");
                        //if (this.signalRService != undefined) this.signalRService.closeHubConnection();
                        this.subjectService.IsQueryOnTimeoutSubject.next(2);
                     }
                     throw error;
                  })
               );
            }
         }
      }

      const authToken = this.storageService.getTokenFromStorage();
      const userConnected = this.storageService.getUserConnected();

      if (userConnected == null) {
         //Handle request without bearer
         //this.frameworkService.logInfo("Add empty accesstoken", "INTERCEPTOR");
         return next.handle(this.addTokenHeader(request, '')).pipe(
            map((event: HttpEvent<any>) => {
               if (event instanceof HttpResponse && event.body !== null) {
                  this.subjectService.IsQueryOnTimeoutSubject.next(0);
               }
               return event;
            }),
            timeout(9000), // Set a timeout of 9 seconds
            catchError(error => {
               if (error.name === 'TimeoutError') {
                  //this.frameworkService.logInfo("TIMEOUT", "interceptor query2 ERROR");
                  //if (this.signalRService != undefined) this.signalRService.closeHubConnection();
                  this.subjectService.IsQueryOnTimeoutSubject.next(1);
               } else {
                  //this.frameworkService.logInfo(error, "interceptor query2 ERROR");
                  //if (this.signalRService != undefined) this.signalRService.closeHubConnection();
                  this.subjectService.IsQueryOnTimeoutSubject.next(2);
               }
               throw error;
            })
         );
      }
      else {
         return next.handle(this.addTokenHeader(request, authToken.accessToken)).pipe(
            map((event: HttpEvent<any>) => {
               if (event instanceof HttpResponse && event.body !== null) {
                  this.subjectService.IsQueryOnTimeoutSubject.next(0);
               }
               return event;
            }),
            timeout(9000), // Set a timeout of 9 seconds
            catchError(error => {
               if (error.name === 'TimeoutError') {
                  //this.frameworkService.logInfo("TIMEOUT", "interceptor query3 ERROR");
                  //if (this.signalRService != undefined) this.signalRService.closeHubConnection();
                  this.subjectService.IsQueryOnTimeoutSubject.next(1);
                  throw error;
               } else {
                  this.isCannotBeLoaded = !this.storageService.isDataPrivacyAccepted() && !this.isNetworkStatusOnline;

                  //this.frameworkService.logInfo(error, "interceptor query3 ERROR");
                  //if (this.signalRService != undefined) this.signalRService.closeHubConnection();
                  this.subjectService.IsQueryOnTimeoutSubject.next(2);
               }
               return next.handle(this.addTokenHeader(request, authToken.accessToken));
            })
         );
      }
   }

   private addTokenHeader(request: HttpRequest<any>, accessToken: string) {
      if (accessToken == "" || request.url.includes('/C20'))
         return request.clone({
            setHeaders: {
               'Content-Type': 'application/json',
               'comment': this.encryptionService.getEncryptX(true)
            }
         });
      else
         return request.clone({
            setHeaders: {
               Authorization: `Bearer ${accessToken}`,
               'Content-Type': 'application/json',
               'comment': this.encryptionService.getEncryptX(true)
            }
         });
   }
}