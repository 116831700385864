<form [formGroup]="form">
   <mat-card class="mat-card-color popupDimensionComiteeMeetingEdition">
      <mat-card-header class="mat-card-header-color">
         <mat-card-title>
            <div class="flexSpaceBetween">
               <div>
                  <span>{{title}} </span>
                  <span *ngIf="comiteeMeeting?.isApproved && !isOnEdition">
                     (approuvée)
                  </span>
                  <span *ngIf="!comiteeMeeting?.isApproved && !isOnEdition">
                     (à approuver)
                  </span>
               </div>
            </div>
         </mat-card-title>
      </mat-card-header>
      <mat-card-content class="mat-card-content-padding">
         <ng-container *ngIf="isOnEdition">

            <div class="hr" *ngIf="crudType != 1">Approbation</div>
            <div class="center" *ngIf="crudType != 1">
               <app-button [visibility]="!isNotPresidentOrAdmin() && !comiteeMeeting?.isApproved" icon="recommend"
                  [class]="'spanButtonSeparator approvedBackcolor'" [text]="'Approve' |
            translate" [isBackgroundOpaque]="true" [disabled]="form.invalid" isSubmit="false"
                  (onClick)="onClickApproved(false)"></app-button>
               <app-button [visibility]="!isNotPresidentOrAdmin() && comiteeMeeting?.isApproved" icon="do_not_touch"
                  [class]="'spanButtonSeparator notYetApprovedBackcolor'" [text]="'NotYetApproved' |
            translate" [isBackgroundOpaque]="true" [disabled]="form.invalid" isSubmit="false"
                  (onClick)="onClickApproved(true)"></app-button>
            </div>

            <div class="hr" *ngIf="crudType != 1">Présences</div>
            <div class="center" *ngIf="crudType != 1">
               <button type="button" mat-raised-button color="Primary" [class]="getClass(item)"
                  (click)="onMemberClick(item)" *ngFor="let item of getAllComiteeRolesUserValues()">
                  {{item.firstName}} {{item.lastName[0]}}
               </button>
            </div>

            <div class="hr">Informations</div>
            <div class="center">
               Heure
               <div>
                  <ngx-timepicker-field class="timepicker-zindex" formControlName="time" [format]="24" [defaultTime]="'20:45'"
                     [confirmBtnTmpl]="okButton" [cancelBtnTmpl]="cancelButton">
                  </ngx-timepicker-field>
                  <ng-template #okButton>
                     <button type="button" mat-raised-button color="primary" [class]="'spanButtonSeparator buttonNormalYes'">OK
                     </button>
                  </ng-template>
                  <ng-template #cancelButton>
                     <button type="button" mat-raised-button color="primary"
                        [class]="'spanButtonSeparator buttonNormalNo'">{{'Cancel' |
                        translate}}</button>
                  </ng-template>
               </div>
            </div>
            <div class="center">
               <div class="br1"></div>
               Lieu
               <input type="text" formControlName="location" class="form-control" />
            </div>

            <div class="br1" *ngIf="crudType != 1"></div>
            <div class="hr" *ngIf="crudType != 1">Informations complémentaires</div>
            <div class="center" *ngIf="crudType != 1">
               <mat-form-field class="textarea" [hidden]="false">
                  <textarea formControlName="pointsToView" matInput rows="15" style="width:98.5%"></textarea>
               </mat-form-field>
            </div>

            <div class="hr" *ngIf="crudType != 1">Convocation / compte-rendu</div>
            <div class="center" *ngIf="crudType != 1">
               <div class="flexSpaceBetween">
                  <div>
                     <app-button [visibility]="true" icon="picture_as_pdf" [class]="'spanButtonSeparator'"
                     [text]="'Choisir le document (pdf) ...'" [isBackgroundOpaque]="true"
                     [disabled]="form.invalid" isSubmit="false" (onClick)="pdfFileInput.click()"></app-button>
                  </div>
                  <div>
                     <app-button [visibility]="pdfFile != null" icon="delete" [class]="'spanButtonSeparator'"
                     [text]="'Supprimer'" [isBackgroundOpaque]="true"
                     [disabled]="form.invalid" isSubmit="false" (onClick)="onPdfReportDelete()"></app-button>
                  </div>
               </div>



               <input hidden (change)="onUploadPdfFile($event)" #pdfFileInput type="file" id="file">
        
               <div *ngIf="pdfFile != null">
                  <div class="br1"></div>
                  <ngx-extended-pdf-viewer [src]="pdfFile" [zoom]="'page-width'" [height]="'auto'"
                     [useBrowserLocale]="true" [pageViewMode]="'infinite-scroll'" [textLayer]="false"
                     [sidebarVisible]="false" [showToolbar]="true" [showSidebarButton]="false" [showFindButton]="false"
                     [showPagingButtons]="false" [showDrawEditor]="false" [showTextEditor]="false" [showZoomButtons]="true"
                     [showPresentationModeButton]="false" [showOpenFileButton]="false" [showPrintButton]="false"
                     [showDownloadButton]="false" [showSecondaryToolbarButton]="false" [showRotateButton]="false"
                     [showHandToolButton]="false" [showScrollingButton]="false" [showSpreadButton]="false"
                     [showPropertiesButton]="false">
                  </ngx-extended-pdf-viewer>
               </div>
               <input matInput formControlName="externalDocumentOfMeetingPdf" hidden="true" />
            </div>

            <div class="br2"></div>
            <div *ngIf="crudType != 1"> <mat-checkbox formControlName="sendNotification" ></mat-checkbox> Envoi d'une notification aux membres du comité.</div>
           

            <div class="br1"></div>
            <div class="flexSpaceBetween">
               <div>
                  <app-button icon="cancel" [class]="'spanButtonSeparator buttonNormalNo'" [text]="cancelTitle"
                     [isBackgroundOpaque]="true" (onClick)="onCancel()"></app-button>
               </div>
               <app-button [visibility]="isDeleteVisible" icon="delete" [class]="'spanButtonSeparator buttonDelete'"
                  [text]="'Delete' | translate" [disabled]="form.invalid" isSubmit="true"
                  (onClick)="onDelete()"></app-button>
               <app-button [visibility]="isSaveVisible" icon="save" [class]="'spanButtonSeparator buttonNormalYes'"
                  [text]="'Save' | translate" [disabled]="form.invalid" isSubmit="true"
                  (onClick)="onSave()"></app-button>
            </div>
         </ng-container>

         <ng-container *ngIf="!isOnEdition && isPdfFileLoaded">
            <div *ngIf="pdfFile == null && pointsToView != ''" class="hr">Informations complémentaires</div>
            <div *ngIf="pdfFile == null && pointsToView != ''" [innerHTML]="getHtml(pointsToView)"></div>
            <div *ngIf="pdfFile == null && pointsToView != ''" class="br2"></div>
            <div class="hr">Convocation / compte-rendu</div>
            <div *ngIf="pdfFile == null">
               Aucun document n'est encore publié.
            </div>
            <div *ngIf="pdfFile != null">
               <div class="br1"></div>
               <ngx-extended-pdf-viewer [src]="pdfFile" [zoom]="'page-width'" [height]="'auto'"
                  [useBrowserLocale]="true" [pageViewMode]="'infinite-scroll'" [textLayer]="false"
                  [sidebarVisible]="false" [showToolbar]="true" [showSidebarButton]="false" [showFindButton]="false"
                  [showPagingButtons]="false" [showDrawEditor]="false" [showTextEditor]="false" [showZoomButtons]="true"
                  [showPresentationModeButton]="false" [showOpenFileButton]="false" [showPrintButton]="false"
                  [showDownloadButton]="false" [showSecondaryToolbarButton]="false" [showRotateButton]="false"
                  [showHandToolButton]="false" [showScrollingButton]="false" [showSpreadButton]="false"
                  [showPropertiesButton]="false">
               </ngx-extended-pdf-viewer>
            </div>
            <div class="br1"></div>
            <div class="flexSpaceBetween">
               <div>
                  <app-button icon="cancel" [class]="'spanButtonSeparator buttonNormalNo'" [text]="cancelTitle"
                     [isBackgroundOpaque]="true" (onClick)="onCancel()"></app-button>
               </div>
            </div>
         </ng-container>
      </mat-card-content>
   </mat-card>
</form>