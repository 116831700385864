<div>
   <div>
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon"
                  style="margin-left:auto">home</span>{{titleDescriptionDisplay}}
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>
            <div *ngFor="let homeNews of allHomeNews">
               <div class="matcard matcardFloat">
                  <app-panel [globalClass]="getNewsClass(homeNews.isCritical)"
                     [urlImage]="getImgBackgroundURL(homeNews.picture)"
                     [isImportant]="homeNews.isCritical">
                     <span panel-title><span
                           [innerHTML]="getHtml(homeNews.isCritical, homeNews.header, false)"></span></span>
                     <div panel-content>
                        <div [innerHTML]="getHtml(false, homeNews.description, homeNews.isShouldAuthenticated)"></div>
                        <div class="dateInfo">
                           <span *ngIf="isInformationEditable() && !isTurnAdminButtonsOff"
                              class="material-icons editButton animatedButton" [routerLink]="['/adminhomeNewslist']"
                              [queryParams]="{sysId: homeNews.sysId}">more_horiz</span><span>&nbsp;({{getUpdatingDate(homeNews)
                              | date:'dd/MM/yyyy'}})</span>
                        </div>
                     </div>
                  </app-panel>
               </div>
            </div>

            <div class="matcard matcardFloat">
               <app-panel [widthPanel]="'400px'">
                  <span panel-title><span
                        class="material-icons material-icons-medium">volunteer_activism</span>&nbsp;Nos sponsors</span>
                  <div panel-content>
                     <div class="carousel-panel-image">
                        <button class="linkButton sameWidth" (click)="goOnSponsors()">
                           <ngb-carousel class="carousel">
                              <ng-template ngbSlide *ngFor="let sponsor of allSponsors">
                                 <div class="carousel-content">
                                    <div class="carousel-image-container"
                                       *ngIf="sponsor.picture != null && sponsor.picture != ''">
                                       <img class="carousel-image" [src]="getImgURL(sponsor.picture)" alt="Sponsor"
                                          loading="lazy">
                                    </div>
                                 </div>
                              </ng-template>
                           </ngb-carousel>
                        </button>
                        <div class="br1"></div>
                        <app-buttonhref [isBackgroundOpaque]="true" [icon]="'info'"
                           [text]="'Plus de détail ...'" [type]="EnumHRefType.HttpRouter" [href]="'/sponsor'"
                           alt="Plus de détail">
                        </app-buttonhref>
                     </div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel [widthPanel]="'400px'">
                  <span panel-title><span
                        class="material-icons material-icons-medium">lightbulb</span>&nbsp;Mutuelle</span>
                  <div panel-content>
                     <div class="br1"></div>
                     <app-buttonhref [isBackgroundOpaque]="true" [icon]="'link'"
                        [text]="'Remboursement des frais d\'inscription'" [type]="EnumHRefType.HttpNewWindow"
                        [href]="'http://monactivite.be/intervention-mutuelle'" alt="Remboursement">
                     </app-buttonhref>



                  </div>
               </app-panel>
            </div>
         </mat-card-content>
      </mat-card>
   </div>
</div>