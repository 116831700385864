<div>
   <div>
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon"
                  style="margin-left:auto">auto_stories</span>{{titleDescriptionDisplay}}
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>
            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>08 novembre 2023</span>
                  <div panel-content>
                     <div class="flexCenter"><img class="image" loading="lazy"
                        src="../../../../sites/vabourlettis/images/articles/Article20231108.jpg"
                        alt="Article20231108"></div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>10 mai 2023</span>
                  <div panel-content>
                     <div class="flexCenter"><img class="image" loading="lazy"
                        src="../../../../sites/vabourlettis/images/articles/Article20230510.png"
                        alt="Article20230510"></div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>22 mars 2023</span>
                  <div panel-content>
                     <div class="flexCenter"><img class="image" loading="lazy"
                        src="../../../../sites/vabourlettis/images/articles/Article20230322.png"
                        alt="Article20230322"></div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>09 décembre 2021</span>
                  <div panel-content>
                     <div class="flexCenter"><img class="image" loading="lazy"
                        src="../../../../sites/vabourlettis/images/articles/Article20211209.jpg"
                        alt="Article20211209"></div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>10 janvier 2018</span>
                  <div panel-content>
                     <div class="flexCenter"><img class="image" loading="lazy"
                        src="../../../../sites/vabourlettis/images/articles/Article20180110.png"
                        alt="Article20180110"></div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>21 septembre 2017</span>
                  <div panel-content>
                     <div class="flexCenter"><img class="image" loading="lazy"
                        src="../../../../sites/vabourlettis/images/articles/Article20170921.png"
                        alt="Article20170921"></div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>20 avril 2017</span>
                  <div panel-content>
                     <div class="flexCenter"><img class="image" loading="lazy"
                        src="../../../../sites/vabourlettis/images/articles/Article20170420.jpg"
                        alt="Article20170420"></div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>13 avril 2017</span>
                  <div panel-content>
                     <div class="flexCenter"><img class="image" loading="lazy"
                        src="../../../../sites/vabourlettis/images/articles/Article20170413.jpg"
                        alt="Article20170413"></div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>06 avril 2017</span>
                  <div panel-content>
                     <div class="flexCenter"><img class="image" loading="lazy"
                        src="../../../../sites/vabourlettis/images/articles/Article20170406.jpg"
                        alt="Article20170406"></div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>22 décembre 2016</span>
                  <div panel-content>
                     <div class="flexCenter"><img class="image" loading="lazy"
                        src="../../../../sites/vabourlettis/images/articles/Article20161222.jpg"
                        alt="Article20161222"></div>
                  </div>
               </app-panel>
            </div>
         </mat-card-content>
      </mat-card>
   </div>
</div>