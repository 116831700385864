import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { enumActivityType, enumCacheStatusLoad, enumMatchDisplayStatus } from 'src/sites/core/enum/Enum';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { PrintService } from 'src/sites/core/services/print.service';
import { match } from '../../models/match';
import { ranking } from '../../models/ranking';
import { team } from '../../models/team';
import { CacheService } from '../../services/cache.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
   selector: 'app-ranking',
   templateUrl: './ranking.component.html',
   styleUrls: ['./ranking.component.scss'],
   encapsulation: ViewEncapsulation.None
})

export class RankingComponent implements OnInit {
   public allMainRankingOfTeamDic = new Map<Number, ranking[]>();
   public allMatchOfTeamDic = new Map<Number, match[]>();
   public allReserveRankingOfTeamDic = new Map<Number, ranking[]>();
   public allResultsSwitch: boolean = false;
   public allTeams: team[] = [];
   public displayedMatchColumns = [];
   public displayedMatchHeaderColumns = [];
   public displayedRankingColumns = [];
   public displayedRankingHeaderColumns = [];
   public isDisplayOnAMobile: boolean = false;
   public titleDescriptionDisplay: string = "";
   public allToggleLabel: string = "";
   public toggleTeamList = [];
   public allToggleTeamAreEnabled = true;

   constructor(
      private frameworkService: FrameworkService,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private translateService: TranslateService,
      public sanitizer: DomSanitizer,
      private printService: PrintService) {
   }



   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });

      this.getScreenSize();

      if (this.isDisplayOnAMobile) {
         this.displayedRankingColumns = ['position', 'logo', 'teamName', 'jo', 'pPlus', 'pMinus', 'ga', 'pe', 'sPlus', 'sMinus', 'coef'];
         this.displayedRankingHeaderColumns = ['Pos', "", 'Equipe', 'Jo', 'P+', 'P-', 'Ga', 'Pe', 'S+', 'S-', "Coef"];
      }
      else {
         this.displayedRankingColumns = ['position', 'logo', 'teamName', 'jo', 'pPlus', 'pMinus', 'ga', 'g3', 'g2', 'p1', 'p0', 'pe', 'ff', 'sPlus', 'sMinus', 'coef'];
         this.displayedRankingHeaderColumns = ['Pos', 'logo', 'Equipe', 'Jo', 'P+', 'P-', 'Ga', 'G3', 'G2', 'P1', 'P0', 'Pe', 'Ff', 'S+', 'S-', "Coef"];
      }

      if (this.isDisplayOnAMobile) {
         this.displayedMatchColumns = ['day', 'score', 'fromTeam'];
         this.displayedMatchHeaderColumns = ['Date', 'Score', 'Rencontre'];
      }
      else {
         this.displayedMatchColumns = ['day', 'score', 'scoreDetail', 'fromTeam'];
         this.displayedMatchHeaderColumns = ['Date', 'Score', 'Détail du score', 'Rencontre'];
      }
   }


   @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

   public classForMatchOfClub(match: match, column: string) {
      if (column == "fromTeam")
         return "matCell matCell100Percent";
      else
         return "matCell";
   }

   public classForTeamNameOfClub(ranking: ranking, main: boolean) {
      return ranking.teamName.toUpperCase().includes(this.frameworkService.getSportsClubName().toUpperCase()) ? "matCell matRowHighlight" + (main ? "" : "Reserve") : "matCell";
   }

   public displayColumnRanking(column: string, ranking: ranking) {
      switch (column) {
         case "logo":
            return "<img src='" + ranking.logo + "' alt='Logo' width='20' height='20'>";
         default:
            return ranking[column];
      }
   }

   public displayColumn(column: string, match: match) {
      switch (column) {
         case "score":
            let scores = match.score.split('-');
            let scoreRate = "<span class='material-icons matcard-title-icon matIconColorForPrint'>sports_volleyball</span>";
            if (match.isAtHome && Number(scores[0]) > Number(scores[1]) || (!match.isAtHome && Number(scores[0]) < Number(scores[1])))
               scoreRate = "<span class='material-icons matcard-title-icon matIconColorForPrint'>emoji_events</span>";
            if (match.isAtHome == null)
               scoreRate = "";

            return "<div class='matchScoreRate'>" + scoreRate + " <span class='matchScore'>" + match.score + ((match.scoreReserve == "") ? "" : "/" + match.scoreReserve) + "</span></div>";
         case "day":
            return this.frameworkService.getDateOnlyFormatForScreen(match.day.toString());
         case "fromTeam":
            let temp: string = "";
            if (match.type != enumActivityType[enumActivityType.CHAMPIONSHIP] && match.type != enumActivityType[enumActivityType.YOUNG]) {
               temp += this.translateService.instant(match.type) + "<div class='br1'></div>";
            }
            else {
               if (match.type == enumActivityType[enumActivityType.CHAMPIONSHIP] && match.number.startsWith("CL"))
                  temp += this.translateService.instant(match.type) + " " + this.translateService.instant("CHAMPIONSHIP_FINAL") + "<div class='br1'></div>";
            }

            if (match.isAtHome != null) {
               if (match.isAtHome)
                  temp += "<span class='VAB'>" + this.frameworkService.getSportsClubNameToUseOnDisplay(this.isDisplayOnAMobile) + "</span> - " + match.awayTeam + " " + (this.isDisplayOnAMobile ? (match.scoreDetail != "" ? " <br class='noPrint'> " : "") + match.scoreDetail : "");
               else
                  temp += " " + match.fromTeam + " - <span class='VAB'>" + this.frameworkService.getSportsClubNameToUseOnDisplay(this.isDisplayOnAMobile) + "</span>" + (this.isDisplayOnAMobile ? (match.scoreDetail != "" ? " <br class='noPrint'> " : "") + match.scoreDetail : "");
            }
            else
               temp += " " + match.fromTeam + " - " + match.awayTeam + (this.isDisplayOnAMobile ? (match.scoreDetail != "" ? " <br class='noPrint'> " : "") + match.scoreDetail : "");
            return temp;
         default:
            return match[column];
      }
   }

   public getAllResultsIcon() {
      return this.allResultsSwitch ? "remove" : "view_list";
   }

   public getAllResultsText() {
      this.subjectService.IsButtonInLoadingSubject.next(false);
      return this.allResultsSwitch ? "VAB" : "Tout";
   }

   public getDateReport(ranking: ranking) {
      if (ranking == null)
         return "";
      else
         return "(" + this.frameworkService.getDateOnlyFormatForScreen(ranking.reportingDate.toString()) + ")";
   }

   public getInvoiceDetails(invoiceId) {
      const amount = Math.floor((Math.random() * 100));
      return new Promise(resolve =>
         setTimeout(() => resolve({ amount }), 1000)
      );
   }

   public getMainRankingOfTeam(team: team) {
      return Array.from(this.allMainRankingOfTeamDic.get(team.sysId).values());
   }

   public getMatCardHeaderColorResult(team: team) {
      return "mat-card-header-color-result " + team.class;
   }

   public getMatchOfTeam(team: team) {
      return Array.from(this.allMatchOfTeamDic.get(team.sysId).values());
   }

   public getReserveRankingOfTeam(team: team) {
      return Array.from(this.allReserveRankingOfTeamDic.get(team.sysId).values());
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public loadAllMatchOfTeams(all: boolean) {
      this.allTeams.forEach(i => {
         let allMatchs = [];
         if (all)
            allMatchs = this.cacheService.getAllMatchs().filter(m => m.teamSysId == i.sysId && m.status == enumMatchDisplayStatus[enumMatchDisplayStatus.JOU]).sort((a, b) => this.frameworkService.getDateFormatYyyyMmDd(a.day) + a.team + a.type > this.frameworkService.getDateFormatYyyyMmDd(b.day) + b.team + b.type ? -1 : 1);
         else
            allMatchs = this.cacheService.getAllMatchs().filter(m => m.teamSysId == i.sysId && m.status == enumMatchDisplayStatus[enumMatchDisplayStatus.JOU] &&
               (m.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) ||
                  m.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()))).sort((a, b) => this.frameworkService.getDateFormatYyyyMmDd(a.day) + a.team + a.type > this.frameworkService.getDateFormatYyyyMmDd(b.day) + b.team + b.type ? -1 : 1);
         this.allMatchOfTeamDic.set(i.sysId, allMatchs);
      });
   }

   public onAllResultsClick() {
      this.allResultsSwitch = !this.allResultsSwitch;
      this.loadAllMatchOfTeams(this.allResultsSwitch);
   }

   public onPrint(divName) {
      const printContents = document.getElementById(divName).outerHTML;
      const originalContents = document.body.outerHTML;

      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;

      window.location.replace(window.location.href.replace('http', !window.location.href.includes("localhost:4300") ? 'https' : 'http'));
      window.location.reload();
   }

   public loadAllRankingOfTeams() {
      let selectedTeamsSysId = [];
      this.toggleTeamList.forEach(element => {
         if (!element.enabled) selectedTeamsSysId.push(element.sysId);
      });

      this.allTeams = this.cacheService.getAllTeamsWithoutPicture().filter(i => i.division != "" && selectedTeamsSysId.Contains(i.sysId)).OrderBy(i => i.teamDisplay).ToArray();
      this.allTeams.forEach(i => {
         let allRanking = this.cacheService.getAllRankings().filter(j => j.teamSysId == i.sysId && !j.isReserve).OrderBy(i => i.position).ToArray();
         this.allMainRankingOfTeamDic.set(i.sysId, allRanking);
         allRanking = this.cacheService.getAllRankings().filter(j => j.teamSysId == i.sysId && j.isReserve).OrderBy(i => i.position).ToArray();
         this.allReserveRankingOfTeamDic.set(i.sysId, allRanking);
      });
   
      this.loadAllMatchOfTeams(this.allResultsSwitch);





      // this.allMatchs = this.cacheService.getAllMatchs().filter(m => selectedTeamsSysId.Contains(m.teamSysId) && m.status == enumMatchDisplayStatus[enumMatchDisplayStatus.AJO] &&
      //    (m.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) ||
      //       m.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName())))
      //    .sort((a, b) => this.frameworkService.getDateFormatYyyyMmDd(a.day) + a.team + a.type < this.frameworkService.getDateFormatYyyyMmDd(b.day) + b.team + b.type ? -1 : 1);
   }



   public onFilterChange(): void {
      this.setAllToggleTeamAreEnabled();
      if (this.allToggleTeamAreEnabled)
         this.allToggleLabel = "Toute";
      else
         this.allToggleLabel = "Aucune";
      this.loadAllRankingOfTeams(); 
   }

   public onPrintRanking() {
      this.printService.onDataReady();
      this.printService.printDocument('ranking');
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   
   onTeamToggleChanged(event: MatSlideToggleChange, item: any) {
      item.enabled = event.checked;
      let isAllEnabled: boolean = false;
      this.toggleTeamList.forEach(element => {
         if (element.enabled) isAllEnabled = true;
      });
      this.allToggleTeamAreEnabled = isAllEnabled;
      this.onFilterChange();
   }

   setAllToggleTeamAreEnabled() {
      let isAllEnabled: boolean = false;
      this.toggleTeamList.forEach(element => {
         if (element.enabled) isAllEnabled = true;
      });
      this.allToggleTeamAreEnabled = isAllEnabled;
   }

   onToggleAllTeams(event: any) {
      this.toggleTeamList.forEach(item => item.enabled = event.checked);
      
      this.onFilterChange();
   }


   public refresh() {
      this.allToggleTeamAreEnabled = false;

      this.toggleTeamList = this.cacheService.getAllTeamsWithoutPicture().filter(i => i.division != "" && i.sysId > 1 && i.isRankingAvailable).OrderBy(i => i.teamDisplay).ToArray().map(x => (
         {
            name: x.teamCategory,
            sysId: x.sysId,
            enabled: false,
            class: x.class
         })
      );
      this.onFilterChange();
   }

   public translatePage() {
      //RankingTitle
      this.translateService.get(['RankingTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['RankingTitle'];
         });
   }
}
