<form [formGroup]="form">
   <div>
      <mat-card class="mat-card-center-95P">
         <mat-card-content>
            <mat-card>
               <mat-card-header class="mat-card-header-color">
                  <mat-card-title>
                     <div class="flexLeft">
                        <div>
                           <span class="material-icons matcard-title-icon"
                              style="margin-left:auto">people</span>{{activesMembersCount}} membres /
                           {{inactivesMembersCount}} inactif(s) / {{inactivesLoginMembersCount}} désaffilié(s)
                        </div>
                     </div>
                  </mat-card-title>
               </mat-card-header>
               <mat-card-content>
                  <div class="mainmatcard">
                     <div class="mainmatcard filter">
                        <input matInput type="text" [maxLength]="50" formControlName="memberFilter" (keyup)="refresh()"
                           class="form-control shadow-none"
                           placeholder="Enter ici une valeur pour rechercher un membre" />
                     </div>
                     <div class="mainmatcard">
                        <mat-tab-group [(selectedIndex)]="tabIndex">
                           <mat-tab label="par prénom">
                              <div class="card">
                                 <div *ngFor="let member of allMembersByFirstname; let i = index;" class="href"
                                    (click)="changeProfile(member, 1)">
                                    <div class="flexStart"
                                       *ngIf="getPreviousMemberByFirstname(i) != member.firstName[0]" class="hr">
                                       <div class="fontHeader borderHeader">{{member.firstName[0]}}</div>
                                       <div></div>
                                    </div>
                                    <div class="flexStart">
                                       <div [class]="getIsInactiveClass(member, false)"><span class="material-icons"
                                             style="margin-left:auto">touch_app</span>&nbsp;&nbsp;{{member.firstName}}
                                       </div>
                                       <div [class]="getIsInactiveClass(member, true)">&nbsp;{{member.lastName}}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </mat-tab>
                           <mat-tab label="nom">
                              <div class="card">
                                 <div *ngFor="let member of allMembersByLastname; let i = index;" class="href"
                                    (click)="changeProfile(member, 0)">
                                    <div class="flexStart" *ngIf="getPreviousMember(i) != member.lastName[0]"
                                       class="hr">
                                       <div class="fontHeader borderHeader">{{member.lastName[0]}}</div>
                                       <div></div>
                                    </div>
                                    <div class="flexStart">
                                       <div [class]="getIsInactiveClass(member, false)"><span class="material-icons"
                                             style="margin-left:auto">touch_app</span>&nbsp;&nbsp;{{member.lastName}}
                                       </div>
                                       <div [class]="getIsInactiveClass(member, true)">&nbsp;{{member.firstName}}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </mat-tab>
                           <mat-tab label="équipe">
                              <div class="card">
                                 <div *ngFor="let member of allMembersByAllTeam; let i = index;" class="href"
                                    (click)="changeProfile(member, 2)">
                                    <div class="flexStart"
                                       *ngIf="getPreviousMemberByAllTeam(i) != member.team?.teamCategory" class="hr">

                                       <div class="fontHeader borderHeader"
                                          *ngIf="allTeamActiveCount[i] + allTeamInactiveCount[i] + allTeamInactiveLoginCount[i] > 0 && member.team.teamDisplay != ''">
                                          {{allTeamActiveCount[i]}} {{member.team.teamDisplay}}<br /><span
                                             style="font-size: x-small;"
                                             *ngIf="allTeamInactiveCount[i] > 0">({{allTeamInactiveCount[i]}}
                                             inactifs)</span>
                                          <span *ngIf="allTeamInactiveLoginCount[i] > 0"> <span
                                                style="font-size: x-small;">({{allTeamInactiveLoginCount[i]}}
                                                désaffilié(s))</span></span>
                                       </div>

                                       <div class="fontHeader borderHeader"
                                          *ngIf="allTeamActiveCount[i] + allTeamInactiveCount[i] + allTeamInactiveLoginCount[i] > 0 && member.team.teamDisplay == ''">
                                          {{allTeamActiveCount[i]}} Hors équipe<br /><span style="font-size: x-small;"
                                             *ngIf="allTeamInactiveCount[i] > 0">({{allTeamInactiveCount[i]}}
                                             inactifs)</span>
                                          <span *ngIf="allTeamInactiveLoginCount[i] > 0"> <span
                                                style="font-size: x-small;">({{allTeamInactiveLoginCount[i]}}
                                                désaffilié(s))</span></span>
                                       </div>
                                       <div></div>
                                    </div>
                                    <div class="flexSpaceBetween">
                                       <div>
                                          <span class="material-icons"
                                             style="margin-left:auto">touch_app</span>&nbsp;&nbsp;<span
                                             [class]="getIsInactiveClass(member, false)">{{member.firstName}}</span><span
                                             *ngIf="accessRightsService.isUserConnectedAdministratorOrSupervisor()">&nbsp;<span
                                                style="font-style: italic; font-size:x-small">[{{member.sysId}}]</span></span>
                                       </div>
                                       <div>
                                          <div class="flexSpaceBetween">
                                             <div><span
                                                   [class]="getIsInactiveClass(member, true)">{{member.lastName}}</span>
                                                <span [class]="getIsInactiveClass(member, true)"
                                                   *ngIf="member.jacketNumber != 0 && member.jacketNumber != -1">&nbsp;({{member.jacketNumber}})</span>
                                                <span *ngIf="member.jacketNumber == 0">&nbsp;(Coach)</span>
                                             </div>
                                             <div class="picture">
                                                <img class="picture"
                                                   *ngIf="member.picture != null && member.picture != ''"
                                                   [src]="getImgURL(member.picture)" loading="lazy">
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </mat-tab>
                           <mat-tab label="rôles">
                              <ng-container *ngFor="let role of allRolesOfMembers">
                                 <div class="matcard matcardFloat">
                                    <app-panel [widthPanel]="'500px'" *ngIf="getAllUsersOfRole(role.name)?.length > 0"
                                       [headerClass]="'text-muted fontHeader borderHeader mat-card-header-color'">
                                       <span panel-title>&nbsp;{{role.name}}</span>
                                       <div panel-content>
                                          <ng-container *ngFor="let u of getAllUsersOfRole(role.name)">
                                             <div class="flexLeft">
                                                <div (click)="changeProfile(u, 3)"
                                                   [class]="getIsInactiveClass(u, false)"><span class="material-icons"
                                                      style="margin-left:auto">touch_app</span>&nbsp;&nbsp;{{u.firstName}}
                                                </div>
                                                <div (click)="changeProfile(u, 3)"
                                                   [class]="getIsInactiveClass(u, false)">&nbsp;{{u.lastName}}</div>
                                                &nbsp;
                                                <span
                                                   *ngIf="u.team.teamCategory != '' && (((isMemberCoachOnly(u) && isUserRoleCoach(role.name)) || isUserRoleTeamContact(role.name) || isUserRoleTeamDelegate(role.name) || isUserRoleAssistantCoach(role.name)))">({{u.team.teamCategory}})&nbsp;</span>
                                                <span
                                                   *ngIf="u.team.teamCategory == '' && (((isMemberCoachOnly(u) && isUserRoleCoach(role.name)) || isUserRoleTeamContact(role.name) || isUserRoleTeamDelegate(role.name) || isUserRoleAssistantCoach(role.name)))">(Equipe
                                                   non définie)&nbsp;</span>
                                             </div>
                                          </ng-container>
                                       </div>
                                    </app-panel>
                                 </div>
                              </ng-container>
                           </mat-tab>

                           <mat-tab label="Monitoring"
                              *ngIf="accessRightsService.isUserConnectedAdministratorOrSupervisor() || accessRightsService.isUserConnectedPresident() || accessRightsService.isUserConnectedSecretary()">
                              <app-panel *ngIf="allMemberWithoutPhoneNumber.length > 0">
                                 <span panel-title>Numéro de téléphone non renseigné
                                    ({{allMemberWithoutPhoneNumber.length}})</span>
                                 <div panel-content>
                                    <div *ngFor="let member of allMemberWithoutPhoneNumber; let i = index;" class="href"
                                       (click)="changeProfile(member, 0)">
                                       <div class="flexStart">
                                          <div [class]="getIsInactiveClass(member, false)"><span class="material-icons"
                                                style="margin-left:auto">touch_app</span>&nbsp;&nbsp;{{member.lastName}}
                                          </div>
                                          <div [class]="getIsInactiveClass(member, true)">&nbsp;{{member.firstName}}
                                             ({{member.team?.teamCategory}})
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </app-panel>
                              <app-panel *ngIf="allMemberWithoutEmail.length > 0">
                                 <span panel-title>Email non renseigné ({{allMemberWithoutEmail.length}})</span>
                                 <div panel-content>
                                    <div *ngFor="let member of allMemberWithoutEmail; let i = index;" class="href"
                                       (click)="changeProfile(member, 0)">
                                       <div class="flexStart">
                                          <div [class]="getIsInactiveClass(member, false)"><span class="material-icons"
                                                style="margin-left:auto">touch_app</span>&nbsp;&nbsp;{{member.lastName}}
                                          </div>
                                          <div [class]="getIsInactiveClass(member, true)">&nbsp;{{member.firstName}}
                                             ({{member.team?.teamCategory}})
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </app-panel>
                              <app-panel *ngIf="allMemberWithoutPicture.length > 0">
                                 <span panel-title>Photo non renseignée ({{allMemberWithoutPicture.length}})</span>
                                 <div panel-content>
                                    <div *ngFor="let member of allMemberWithoutPicture; let i = index;" class="href"
                                       (click)="changeProfile(member, 0)">
                                       <div class="flexStart">
                                          <div [class]="getIsInactiveClass(member, false)"><span class="material-icons"
                                                style="margin-left:auto">touch_app</span>&nbsp;&nbsp;{{member.lastName}}
                                          </div>
                                          <div [class]="getIsInactiveClass(member, true)">&nbsp;{{member.firstName}}
                                             ({{member.team?.teamCategory}})
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </app-panel>
                              <app-panel *ngIf="allMemberWithoutLoginIn.length > 0">
                                 <span panel-title>Compte inactivé ({{allMemberWithoutLoginIn.length}})</span>
                                 <div panel-content>
                                    <div *ngFor="let member of allMemberWithoutLoginIn; let i = index;" class="href"
                                       (click)="changeProfile(member, 0)">
                                       <div class="flexStart">
                                          <div [class]="getIsInactiveClass(member, false)"><span class="material-icons"
                                                style="margin-left:auto">touch_app</span>&nbsp;&nbsp;{{member.lastName}}
                                          </div>
                                          <div [class]="getIsInactiveClass(member, true)">&nbsp;{{member.firstName}}
                                             ({{member.team?.teamCategory}})
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </app-panel>
                              <app-panel *ngIf="allMemberWithoutJacketNumber.length > 0">
                                 <span panel-title>Numéro de maillot non communiqué
                                    ({{allMemberWithoutJacketNumber.length}})</span>
                                 <div panel-content>
                                    <div *ngFor="let member of allMemberWithoutJacketNumber; let i = index;"
                                       class="href" (click)="changeProfile(member, 0)">
                                       <div class="flexStart">
                                          <div [class]="getIsInactiveClass(member, false)"><span class="material-icons"
                                                style="margin-left:auto">touch_app</span>&nbsp;&nbsp;{{member.lastName}}
                                          </div>
                                          <div [class]="getIsInactiveClass(member, true)">&nbsp;{{member.firstName}}
                                             ({{member.team?.teamCategory}})
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </app-panel>
                              <app-panel *ngIf="allMemberWithoutContribution.length > 0">
                                 <span panel-title>Cotisation non payée ({{allMemberWithoutContribution.length}})</span>
                                 <div panel-content>
                                    <div *ngFor="let member of allMemberWithoutContribution; let i = index;"
                                       class="href" (click)="changeProfile(member, 0)">
                                       <div class="flexStart">
                                          <div [class]="getIsInactiveClass(member, false)"><span class="material-icons"
                                                style="margin-left:auto">touch_app</span>&nbsp;&nbsp;{{member.lastName}}
                                          </div>
                                          <div [class]="getIsInactiveClass(member, true)">&nbsp;{{member.firstName}}
                                             ({{member.team?.teamCategory}})
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </app-panel>
                           </mat-tab>

                           <mat-tab label="Opérations">
                              <div class="card">

                                 <app-panel
                                    *ngIf="accessRightsService.isUserConnectedAdministratorOrSupervisor() || accessRightsService.isUserConnectedPresident() || accessRightsService.isUserConnectedSecretary()">
                                    <span panel-title>Procédure de login
                                       <app-button icon="send" class="spanButtonSeparator noPrint" [text]="'Envoyer'"
                                          isIconShowOnlyOnMobile="true" [isBackgroundOpaque]="true"
                                          (onClick)="onClick_LoginProcedure()"
                                          [disabled]="this.allMembersSelected.length == 0 || !isNetworkStatusOnline"></app-button></span>
                                 </app-panel>

                                 <app-panel>
                                    <span panel-title>Exportation en VCard
                                       <app-button icon="send" class="spanButtonSeparator noPrint" [text]="'Envoyer'"
                                          isIconShowOnlyOnMobile="true" [isBackgroundOpaque]="true"
                                          (onClick)="onClick_SendToVCard()"
                                          [disabled]="this.allMembersSelected.length == 0 || !isNetworkStatusOnline"></app-button></span>
                                 </app-panel>

                                 <app-panel
                                    *ngIf="accessRightsService.isUserConnectedAdministratorOrSupervisor() || accessRightsService.isUserConnectedPresident() || accessRightsService.isUserConnectedSecretary()">
                                    <span panel-title>Mailing <app-button icon="send"
                                          class="spanButtonSeparator noPrint" [text]="'Envoyer'"
                                          isIconShowOnlyOnMobile="true" [isBackgroundOpaque]="true"
                                          (onClick)="onClick_Mailing()"
                                          [disabled]="this.allMembersSelected.length == 0 || !isNetworkStatusOnline"></app-button></span>
                                    <div panel-content>
                                       <div class="hr">Sujet</div>
                                       <div>
                                          <mat-form-field class="textarea" [hidden]="false">
                                             <textarea formControlName="subject" matInput rows="2"
                                                style="width:97.5%"></textarea>
                                          </mat-form-field>
                                       </div>
                                       <div class="hr">Message</div>
                                       <div>
                                          <mat-form-field class="textarea" [hidden]="false">
                                             <textarea formControlName="message" matInput rows="15"
                                                style="width:97.5%"></textarea>
                                          </mat-form-field>
                                       </div>
                                    </div>
                                 </app-panel>


                                 <app-panel>
                                    <span panel-title>{{allMembersSelected.length}} destinataire(s)</span>
                                    <div panel-content>
                                       <span>
                                          <mat-checkbox formControlName="allMembersSelectedCheckbox"
                                             (change)="onChange_AllMembersSelected($event)">
                                             <div>
                                                Tous/Aucun
                                             </div>
                                          </mat-checkbox>
                                          <mat-checkbox formControlName="withActiveMembersSelectedCheckbox"
                                             (change)="onChange_WithActiveMembersSelected($event)">
                                             <div>
                                                Avec désaffiliés et inactifs
                                             </div>
                                          </mat-checkbox>
                                       </span>

                                       <ng-container formArrayName="allMembersSelectedArray">
                                          <section *ngFor="let member of allMembersByTeam; let i = index;">
                                             <div *ngIf="getPreviousMemberByTeam(i) != member.team.teamCategory;"
                                                class="hr">
                                                {{member.team.teamDisplay}}
                                             </div>
                                             <mat-checkbox [formControlName]="i"
                                                (change)="onChange_MemberSelected($event, member)" [value]="member">
                                                <div [class]="getIsInactiveClass(member, false)">{{member.firstName}}
                                                   {{member.lastName}}
                                                </div>
                                             </mat-checkbox>
                                          </section>
                                       </ng-container>
                                    </div>

                                 </app-panel>
                              </div>
                           </mat-tab>
                        </mat-tab-group>
                     </div>
                  </div>
               </mat-card-content>
            </mat-card>
         </mat-card-content>
      </mat-card>
   </div>
</form>