<div [class]="getEventStyle()" *ngIf="visible" (click)="onEventClick()">
    <!-- Date -->
    <div *ngIf="(isMatch || isMatchStatus || isMatchStatusDetail) && !isTrainingDay && !isExtraTrainingDay && !isTournament"
        [class]="'flexLeftRight'">
        <div><span class="material-icons" *ngIf="isClickable">touch_app</span> <span>{{day}}</span></div>
        <div>
            <span class="material-icons VAB" *ngIf="isAtHome && !isClosingDay">house</span>
            <span class="VAB" *ngIf="isAtHome && !isClosingDay">{{matchTypeDisplay}}</span>
            <span *ngIf="!isAtHome && !isClosingDay">{{matchTypeDisplay}}</span>
        </div>
    </div>

    <div *ngIf="isTrainingDay || isExtraTrainingDay || isTournament" [class]="'flexLeftRight'">
        <div><span class="material-icons" *ngIf="isClickable">touch_app</span> <span>{{day}}</span></div>
        <div>{{matchTypeDisplay}}</div>
    </div>

    <!-- Titre "Equipe/Type" -->
    <div [class]="matchDetailAlignment" *ngIf="!isMatch && !isMatchStatus && !isMatchStatusDetail">
        <span class="material-icons VAB" *ngIf="isAtHome">house</span>
        <span style="font-size:medium" *ngIf="(isMatchFull || isMatchResult)">{{teamDisplay}}</span>
        <span *ngIf="!isTournament && !isExtraTrainingDay"> /
            <span class="VAB" *ngIf="isAtHome">{{matchTypeDisplay}}</span>
            <span *ngIf="!isAtHome">{{matchTypeDisplay}}</span>
        </span>
    </div>

    <!-- Score première -->
    <div [class]="matchDetailAlignment" *ngIf="score == '' && isMatchResult">
        (Score non communiqué)
    </div>
    <div [class]="matchDetailAlignment" *ngIf="score != '' && isMatchResult && !isVictory">
        <span class='material-icons-bigger flipIconAnimate'>sports_volleyball</span> <span class='flipIconAnimate'
            style='font-size:16px'>Défaite {{score}}</span>
    </div>
    <div [class]="matchDetailAlignment" *ngIf="score != '' && isMatchResult && isVictory">
        <span class='material-icons-bigger flipIconAnimate'>emoji_events</span> <span class='flipIconAnimate'
            style='font-size:16px'>Victoire {{score}}</span>
    </div>

    <!-- Contre -->
    <ng-container *ngIf="!isTournament && !isExtraTrainingDay">
        <div [class]="matchDetailAlignment" *ngIf="!isAtHome && fromTeam != '' && isMatchFull"><span
                style="font-size:medium">{{fromTeam}}</span> - VAB</div>
        <div [class]="matchDetailAlignment" *ngIf="isAtHome && awayTeam != '' && isMatchFull">VAB - <span
                style="font-size:medium">{{awayTeam}}</span></div>
        <div [class]="matchDetailAlignment"
            *ngIf="!isAtHome && fromTeam != '' && (isMatchResult || isMatch || isMatchStatus || isMatchStatusDetail)">
            contre <span style="font-size:medium">{{fromTeam}}</span></div>
        <div [class]="matchDetailAlignment"
            *ngIf="isAtHome && awayTeam != '' && (isMatchResult || isMatch || isMatchStatus || isMatchStatusDetail)">
            contre <span style="font-size:medium">{{awayTeam}}</span></div>
    </ng-container>

    <ng-container *ngIf="isTournament || isExtraTrainingDay">
        <div [class]="matchDetailAlignment"
            *ngIf="!isAtHome && fromTeam != '' && (isMatchFull || isMatchStatus|| isMatchStatusDetail)"><span
                style="font-size:medium">{{fromTeam}}</span></div>
        <div [class]="matchDetailAlignment"
            *ngIf="isAtHome && awayTeam != '' && (isMatchFull || isMatchStatus|| isMatchStatusDetail)"><span
                style="font-size:medium">{{awayTeam}}</span></div>
    </ng-container>

    <!-- Score première détail -->
    <div [class]="matchDetailAlignment" *ngIf="scoreDetail != '' && isMatchResult">
        {{scoreDetail}}
    </div>

    <!-- Score réserve -->
    <div [class]="matchDetailAlignment" *ngIf="scoreReserve != '' && scoreReserve != '0 - 0' && isMatchResult && !isYoung && !isHainautCup">
        Réserve : {{scoreReserve}}
    </div>
    <div [class]="matchDetailAlignment" *ngIf="(scoreReserve == '' || scoreReserve == '0 - 0') && isMatchResult && !isYoung && !isHainautCup">
        Réserve : (non communiqué)
    </div>

    <!-- Score position -->
    <div [class]="matchDetailAlignment" style="font-size: xx-small"
        *ngIf="fromTeamPosDisplay + awayTeamPosDisplay != '' && (isMatchFull)">
        ({{matchNumber}}) {{fromTeamPosDisplay}} contre {{awayTeamPosDisplay}}</div>

    <!-- Heures -->
    <div [class]="matchDetailAlignment"
        *ngIf="time != '' && time != '00H00' && (isMatchFull || isMatch || isMatchStatus || isMatchStatusDetail)">
        {{time}}
        <ng-container
            *ngIf="timeReserve != '' && !isFriendlyMatch && !isExtraTrainingDay && !isTournament && match.type != EnumActivityType[EnumActivityType.HAINAUT_CUP]">
            / {{timeReserve}}</ng-container>
        <ng-container *ngIf="timeOnSite != '' && !isExtraTrainingDay && !isTournament && !isFriendlyMatch"> /
            {{timeOnSite}}&nbsp;</ng-container>
        <ng-container
            *ngIf="!isFriendlyMatch && !isTrainingDay && !isExtraTrainingDay && !isTournament && match.type != EnumActivityType[EnumActivityType.HAINAUT_CUP]">
            <app-tooltip [isTooltip]="true" title="Heures" message="Les heures affichées dans l'ordre sont : l'heure du match première, du match réserve et l'heure sur place.<br><br>
        <li>L'heure sur place est calculée au départ de l'heure du match réserve - {{hourPresenceBeforeAtHome}} min à domicile, - {{hourPresenceBeforeNotAtHome}} en déplacement.</li>
        <br>
        (Le coach et l'administrateur peuvent éventuellement modifier ces valeurs)"></app-tooltip>
        </ng-container>
        <ng-container
            *ngIf="!isFriendlyMatch && !isTrainingDay && !isExtraTrainingDay && !isTournament && match.type == EnumActivityType[EnumActivityType.HAINAUT_CUP]">
            <app-tooltip [isTooltip]="true" title="Heures" message="Les heures affichées dans l'ordre sont : l'heure du match première et l'heure sur place.<br><br>
        <li>L'heure sur place est calculée au départ de l'heure du match première - {{hourPresenceBeforeAtHome2}} min à domicile, - {{hourPresenceBeforeNotAtHome2}} min en déplacement.</li>
        <br>
        (Le coach et l'administrateur peuvent éventuellement modifier ces valeurs)"></app-tooltip>
        </ng-container>
    </div>
    <div [class]="matchDetailAlignment"
        *ngIf="(time == '' || time == '00H00') && (isMatchFull || isMatch || isMatchStatus || isMatchStatusDetail) && !isClosingDay && !isTrainingDay">
        (Heure non communiquée)
    </div>

    <!-- Arbitrage -->
    <div [class]="matchDetailAlignment" *ngIf="referee1 != '' && (isMatchFull)">Arbitre 1 : {{referee1}}</div>
    <div [class]="matchDetailAlignment" *ngIf="referee2 != '' && (isMatchFull)">Arbitre 2 : {{referee2}}</div>

    <!-- Adresse -->
    <div [class]="matchDetailAlignment"
        *ngIf="!isFriendlyMatch && !isTournament && !isExtraTrainingDay && !isTrainingDay && (address1+address2).trim() != '' && (address1+address2).trim() != '/' && (isMatchFull || isMatchStatusDetail)">
        <span>
            <span *ngIf="address1.trim() != '' && address1.trim() != 'null'">
                <span class="material-icons">place</span>
                <a href="{{addressUrl}}" target="_blank" rel="noopener" class="auth-link aFontSize"> {{address1}}</a>
            </span>
            <br>
            <span *ngIf="address2.trim() != '' && address2.trim() != 'null'">
                <span class="material-icons" (click)="onEventCopyToClipboard(address2)">copy_all</span>
                <a href="{{addressUrl}}" target="_blank" rel="noopener" class="auth-link aFontSize"> {{address2}}</a>
            </span>
        </span>
    </div>

    <div [class]="matchDetailAlignment"
        *ngIf="(isFriendlyMatch || isTournament || isTrainingDay || isExtraTrainingDay) && address1.trim() != ''">
        {{address1}}
    </div>
    <div *ngIf="(isMatch || isMatchStatusDetail) && isClosingDay">
        <div *ngIf="reasonDisplay != ''">{{reasonDisplay}}</div>
    </div>

    <div *ngIf="(isMatch || isMatchStatusDetail) && !isClosingDay && isPresentVisible">
        <div *ngIf="eventClub.availability == EnumAvailability.NOTPRESENT" class="notPresent padding">
            Absent&nbsp;{{reasonDisplay}}</div>
        <div *ngIf="eventClub.availability == EnumAvailability.PRESENT" class="present padding">Présent</div>
        <div *ngIf="eventClub.availability == EnumAvailability.GUARD" class="guard padding">Présent mais de garde</div>
    </div>
</div>
<div *ngIf="isMatch || isMatchStatus || isMatchStatusDetail">
    <div class="br0"></div>
</div>