<form [formGroup]="form">
    <ng-container>
        <div>{{eventClub.event.name}} à     {{eventClub.event.time.replace(":", "H")}}</div>
        <div>{{eventClub.event.description}}</div>
        <!-- RESERVATION AU PLUS TARD -->
        <div *ngIf="isAvailable(eventClub.event) && eventClub.event.withReservation">
            <b>Réservation au plus tard le <span class="flipIconAnimate">{{getDayOfWeek(eventClub.event.deadline)
                    |
                    translate}}
                    {{getDayNumberOfDate(eventClub.event.deadline)}}
                    {{getMonthStringOfDate(eventClub.event.deadline) |
                    translate}}</span></b> <span *ngIf="!isUserConnected" class="nowrap"><br />Email :
                <a href="mailto:vabourlettis@gmail.com">vabourlettis@gmail.com</a></span>
        </div>

        <!-- RESERVATION CLOTUREE -->
        <div class="br1" *ngIf="isAvailable(eventClub.event) && eventClub.event.withReservation"></div>
        <div *ngIf="!isAvailable(eventClub.event)" class="flipIconAnimate">
            <b>Le réservation est maintenant clôturée. Veuillez contacter
                le(a)
                secrétaire
                si
                vous
                désirez un changement.</b> <span class="nowrap"><br />Email :
                <a href="mailto:vabourlettis@gmail.com">vabourlettis@gmail.com</a></span>
        </div>

        <div *ngIf="isNotificationByEmailActivateForCurrentUser()">
            <app-button icon="email" [class]="'spanButtonSeparator'" [text]="'Recevoir le rapport'"
            [isBackgroundOpaque]="true" (click)="$event.stopPropagation();"
            (onClick)="sendReportByEmail(eventClub.event);"></app-button>
        </div>

        <!-- PRIX ADULTE / ENFANT -->
        <ng-container *ngIf="eventClub.event.personSeniorPrice + eventClub.event.personChildPrice > 0 && eventClub.event.personSeniorPrice != eventClub.event.personChildPrice">
            <ng-container *ngIf="eventClub.event.personSeniorPrice > 0">
                <div>Adulte : <b>{{eventClub.event.personSeniorPrice}}</b> €
                </div>
            </ng-container>
            <ng-container *ngIf="eventClub.event.personChildPrice > 0">
                <div>Enfant : <b>{{eventClub.event.personChildPrice}}</b> €
                </div>
            </ng-container>
        </ng-container>
        <!-- PRIX UNIQUE -->
        <ng-container *ngIf="eventClub.event.personSeniorPrice + eventClub.event.personChildPrice > 0 && eventClub.event.personSeniorPrice == eventClub.event.personChildPrice">
            <ng-container *ngIf="eventClub.event.personSeniorPrice > 0">
                <div>Prix : <b>{{eventClub.event.personSeniorPrice}}</b> €
                </div>
            </ng-container>
        </ng-container>
        <div class="br1" *ngIf="eventClub.event.personSeniorPrice > 0 || eventClub.event.personChildPrice > 0">
        </div>

        <ng-container *ngIf="visible && eventClub != null && eventClub.event.withReservation">
            <div class="br1" *ngIf="!isAvailable(eventClub.event)"></div>
            <div *ngIf="!isAvailable(eventClub.event) && isNetworkStatusOnline"
                [class]="getExpansionClassOnClosing(eventClub.event.sysId)">
                <span *ngIf="isUserConnected && isPresenceLoaded && !isPresent(eventClub.event.sysId)">
                    <span class="material-icons">thumb_down</span>&nbsp;Je n'y participe pas
                </span>
                <span *ngIf="isUserConnected && isPresenceLoaded && isPresent(eventClub.event.sysId)">
                    <span class="material-icons">thumb_up</span>&nbsp;&nbsp;Ma réservation : <span
                        style="font-size: large;">{{(eventClub.event.personSeniorPrice *
                        getSeniorCount(eventClub.event.sysId)) + (eventClub.event.personChildPrice *
                        getChildCount(eventClub.event.sysId)) }} €</span>
                </span>
            </div>

            <!-- PRIX ADULTE / ENFANT -->
            <ng-container
                *ngIf="(eventClub.event.personSeniorPrice > 0) && isUserConnected && !isAvailable(eventClub.event) && isPresenceLoaded && isPresent(eventClub.event.sysId) && !eventClub.event.isAloneActivity && isNetworkStatusOnline">
                <div>
                    <div class="activityGridCenter">
                        <div>{{getSeniorCount(eventClub.event.sysId)}} adulte(s)</div>
                        <div><span>= </span>{{eventClub.event.personSeniorPrice *
                            getSeniorCount(eventClub.event.sysId)}} €</div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="(eventClub.event.personChildPrice > 0) && isUserConnected && !isAvailable(eventClub.event) && isPresenceLoaded && isPresent(eventClub.event.sysId) && !eventClub.event.isAloneActivity && isNetworkStatusOnline">
                <div>
                    <div class="activityGridCenter">
                        <div>{{getChildCount(eventClub.event.sysId)}} enfant(s)</div>
                        <div><span>= </span>{{eventClub.event.personChildPrice * getChildCount(eventClub.event.sysId)}}
                            €</div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!isNetworkStatusOnline">
                <div style="font-size:x-small">(Connexion à internet requise)</div>
            </ng-container>

            <ng-container *ngIf="isNetworkStatusOnline">

                <!-- DEMANDE PARTICULIERE -->
                <div
                    *ngIf="isUserConnected && !isAvailable(eventClub.event) && isPresenceLoaded && isPresent(eventClub.event.sysId) && !eventClub.event.isAloneActivity">
                    <label *ngIf="getParticularDemand(eventClub.event.sysId) != ''">Demande particulière :
                        {{getParticularDemand(eventClub.event.sysId)}}</label>
                </div>

                <!-- PRESENCE SOUPER, ... -->
                <div *ngIf="(isUserConnected || accessRightsService.isUserConnectedInComitee()) && isAvailable(eventClub.event)"
                    [class]="getExpansionClass(eventClub.event.sysId)">
                    <!-- AUCUNE PRESENCE SOUPER SIGNALEE -->
                    <div *ngIf="isPresenceNotYetSet(eventClub.event.sysId)">
                        <div>J'y participe ?
                            <span *ngIf="isUserConnected && isPresenceLoaded">
                                <app-button icon="thumb_up" [class]="'spanButtonSeparator buttonNormalYes'" [text]="'Oui'"
                                    [isBackgroundOpaque]="true" (click)="$event.stopPropagation();"
                                    (onClick)="onClickSwitchPresenceValue(eventClub.event, true);"></app-button>
                            </span>
                            <span *ngIf="isUserConnected && isPresenceLoaded">
                                <app-button icon="thumb_down" [class]="'spanButtonSeparator buttonNormalNo'" [text]="'Non'"
                                    [isBackgroundOpaque]="true" (click)="$event.stopPropagation();"
                                    (onClick)="onClickSwitchPresenceValue(eventClub.event, false);"></app-button>
                            </span>
                        </div>
                    </div>

                    <div
                        *ngIf="isUserConnected && isPresenceLoaded && !isPresent(eventClub.event.sysId) && !isPresenceNotYetSet(eventClub.event.sysId)">
                        <div class="activityGridCenter">
                            <span>Je n'y participe pas</span>
                            <span
                                *ngIf="isUserConnected && isPresenceLoaded && !isPresent(eventClub.event.sysId) && isAvailable(eventClub.event)">
                                <app-button icon="thumb_up" [class]="'spanButtonSeparator presentMember'"
                                    [text]="'J\'y reparticipe'" [isBackgroundOpaque]="true"
                                    (click)="$event.stopPropagation();"
                                    (onClick)="onClickSwitchPresenceValue(eventClub.event, true);"></app-button>
                            </span>
                        </div>
                    </div>

                    
                    <div>
                        <div>
                            <img class="Image" *ngIf="eventClub.event.picture != null && eventClub.event.picture != ''"
                                [src]="getImgURL(eventClub.event.picture)" loading="lazy">
                        </div>
                    </div>

                    <mat-accordion style="width:100%"
                        *ngIf="!isPresenceNotYetSet(eventClub.event.sysId) && isUserConnected && isPresenceLoaded && isPresent(eventClub.event.sysId)">
                        <mat-expansion-panel class="matExpansionPanel" [expanded]="false">
                            <mat-expansion-panel-header style="padding:0px;padding-right:10px">
                                <mat-panel-title
                                    *ngIf="isUserConnected && isPresenceLoaded && isPresent(eventClub.event.sysId)">
                                    <span class="material-icons">thumb_up</span><span
                                        class="infoUpdateReservation">&nbsp;Cliquez ICI pour compléter ou modifier votre
                                        réservation</span>
                                </mat-panel-title>
                                <mat-panel-title *ngIf="!isUserConnected">
                                    <div style="font-size:x-small">(Identification requise)
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <!-- CONTENU PRESENCE SOUPER, ... -->
                            <div class="gridInput"
                                *ngIf="isUserConnected && isAvailable(eventClub.event) && isPresenceLoaded && isPresent(eventClub.event.sysId) && !eventClub.event.isAloneActivity">
                                <div><label>Nb d'adultes : </label></div>
                                <div>
                                    <app-buttonPlusMinus [isPlusButton]="false" (click)="$event.stopPropagation();"
                                        (onClick)="onClickSubstractPerson(true, eventClub.event.sysId)"></app-buttonPlusMinus>
                                </div>
                                <div>
                                    <mat-form-field class="Count" style="width: 40px;font-size: 18px;">
                                        <input matInput type="number"
                                            (change)="onChangePersonCount(true, eventClub.event.sysId)"
                                            oninput="this.value = this.value.replace('.',',').replace('/^d+(.d{1,2})?$/', '');"
                                            formControlName="seniorCount{{eventClub.event.sysId}}" class="shadow-none">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <app-buttonPlusMinus [isPlusButton]="true" (click)="$event.stopPropagation();"
                                        (onClick)="onClickAddPerson(true, eventClub.event.sysId)"></app-buttonPlusMinus>
                                </div>
                                <div
                                    *ngIf="isUserConnected && isAvailable(eventClub.event) && isPresenceLoaded && isPresent(eventClub.event.sysId) && !eventClub.event.isAloneActivity">

                                    <label>
                                        Nb d'enfants : </label>
                                </div>
                                <div
                                    *ngIf="isUserConnected && isAvailable(eventClub.event) && isPresenceLoaded && isPresent(eventClub.event.sysId) && !eventClub.event.isAloneActivity">
                                    <app-buttonPlusMinus [isPlusButton]="false" (click)="$event.stopPropagation();"
                                        (onClick)="onClickSubstractPerson(false, eventClub.event.sysId)"></app-buttonPlusMinus>
                                </div>
                                <div
                                    *ngIf="isUserConnected && isAvailable(eventClub.event) && isPresenceLoaded && isPresent(eventClub.event.sysId) && !eventClub.event.isAloneActivity">
                                    <mat-form-field class="Count" style="width: 40px;font-size: 18px;">
                                        <input matInput type="number"
                                            (change)="onChangePersonCount(false, eventClub.event.sysId)"
                                            oninput="this.value = this.value.replace('.',',').replace('/^d+(.d{1,2})?$/', '');"
                                            formControlName="childCount{{eventClub.event.sysId}}" class="shadow-none">
                                    </mat-form-field>
                                </div>
                                <div
                                    *ngIf="isUserConnected && isAvailable(eventClub.event) && isPresenceLoaded && isPresent(eventClub.event.sysId) && !eventClub.event.isAloneActivity">
                                    <app-buttonPlusMinus [isPlusButton]="true" (click)="$event.stopPropagation();"
                                        (onClick)="onClickAddPerson(false, eventClub.event.sysId)"></app-buttonPlusMinus>
                                </div>
                            </div>

                            <div class="br1" *ngIf="isPresent(eventClub.event.sysId)"></div>
                            <div
                                *ngIf="isUserConnected && isAvailable(eventClub.event) && isPresenceLoaded && isPresent(eventClub.event.sysId) && !eventClub.event.isAloneActivity">
                                <label>Demande particulière : </label>
                                <br />
                                <mat-form-field style="width:100%">
                                    <textarea rows="3" matInput
                                        formControlName="particularDemand{{eventClub.event.sysId}}" class="shadow-none"
                                        (input)="onChangeParticularDemand(eventClub.event.sysId)"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="br1" *ngIf="isPresent(eventClub.event.sysId)"></div>
                            <!-- PRESENCE OU ABSENCE SOUPER SIGNALEE -->
                            <div class="activityGridCenter2" *ngIf="!isPresenceNotYetSet(eventClub.event.sysId)">
                                <div>
                                    <app-button icon="save" [class]="'spanButtonSeparator presentMember'"
                                        [disabled]="!isActivityUpdated(eventClub.event.sysId)"
                                        [text]="'Validate' | translate" [isBackgroundOpaque]="true"
                                        (onClick)="onClickSaveReservationOfActivity(eventClub.event)"
                                        [visibility]="isUserConnected && isPresenceLoaded && isPresent(eventClub.event.sysId) && !eventClub.event.isAloneActivity && isAvailable(eventClub.event)"></app-button>
                                </div>
                                <div>
                                    <span
                                        *ngIf="isUserConnected && isPresenceLoaded && isPresent(eventClub.event.sysId) && isAvailable(eventClub.event)">
                                        <app-button icon="thumb_down" [class]="'spanButtonSeparator notPresentMember'"
                                            [text]="'Je n\'y participe plus'" [isBackgroundOpaque]="true"
                                            (click)="            $event.stopPropagation();"
                                            (onClick)="onClickSwitchPresenceValue(eventClub.event, false);"></app-button>
                                    </span>
                                </div>
                            </div>


                        </mat-expansion-panel>
                    </mat-accordion>

                    <!-- 
                    <div class="activityGridCenter">
                        <div>
                            <button type="button" mat-raised-button color="primary" class="spanButtonSeparator"
                                *ngIf="accessRightsService.isUserConnectedInComitee()" [disabled]="!isNetworkStatusOnline"
                                (click)="onClickShowStats(eventClub.event)">
                                <span class="material-icons" style="margin-left: auto">pie_chart</span>&nbsp;Rapport
                            </button>
                        </div> 
                    </div>

                    <div style="display: block;" *ngIf="isStatDisplayed && accessRightsService.isUserConnectedInComitee()">
                        <div class="matcard">
                            <mat-card>
                                <mat-card-header>
                                    <mat-card-title>Rapport de participation
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                    <div style="width:100%"
                                        [innerHTML]="sanitizer.bypassSecurityTrustHtml(getHtml(getMemberPresenceCountActivity(eventClub.event.sysId)))">
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div class="br1" *ngIf="isPresent(eventClub.event.sysId) == 0"></div>  -->  
                </div>
            </ng-container>
        </ng-container>

    </ng-container>
</form>