import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { enumCacheStatusLoad, enumRoles } from 'src/sites/core/enum/Enum';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { team } from '../../models/team';
import { CacheService } from '../../services/cache.service';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-team',
   templateUrl: './team.component.html',
   styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
   public allTeamsFiltered = [];
   public filter = [];
   public form: FormGroup = new FormGroup({});
   public isTurnAdminButtonsOff: boolean = true;
   public titleDescriptionDisplay: string = "";

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      public accessRightsService: AccessRightsService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private sanitizer: DomSanitizer
   ) {
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
   }

   public getImgURL(url: string) {
      return this.sanitizer.bypassSecurityTrustUrl(JSON.parse(url));
   }

   public getNameOfTeam(team: team) {
      return team.teamDisplay;
   }

   public getPlayersOfTeam(team: team) {
      let allPlayers = this.cacheService.getAllUsersWithAllTeams(false).filter(i => (i.teamSysId == team.sysId || i.team2SysId == team.sysId) && i.sysDeletedOn == null && i.sysIsActive && (
         !i.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.TeamContact])) || this.frameworkService.IsMemberCoachs(i)
      )).sort(function (a, b) {
         if (String(a.jacketNumber).padStart(3, '0') + a.lastName + a.firstName < String(b.jacketNumber).padStart(3, '0') + b.lastName + b.firstName) {
            return -1;
         }
         if (String(a.jacketNumber).padStart(3, '0') + a.lastName + a.firstName > String(b.jacketNumber).padStart(3, '0') + b.lastName + b.firstName) {
            return 1;
         }
         return 0;
      });

      let result = "`";
      allPlayers.forEach(i => {
         let role = "";
         if (i.team.teamCategory.endsWith("M") || i.team.teamCategory.endsWith("D")) {
            role = "(";
            if (i.jacketNumber != null && i.jacketNumber >= 0) {
               if (i.jacketNumber == 0)
                  role += "";
               else
                  role += i.jacketNumber;
            }
            if (i.roleInTeam ?? "" != "") {
               if ((i.roleInClubCustom ?? "").includes("Coach") && !i.roleInTeam.includes("Coach") && !(i.roleInClubCustom ?? "").includes("Assistant coach"))
                  role += (role == "(") ? "Coach, " + i.roleInTeam : ", Coach, " + i.roleInTeam;
               else
                  role += (role == "(") ? i.roleInTeam : ", " + i.roleInTeam;
            }
            else {
               if ((i.roleInClubCustom ?? "").includes("Coach") && !(i.roleInClubCustom ?? "").includes("Assistant coach"))
                  role += (role == "(") ? "Coach" : ", Coach";
            }
            role += ") ";
         }

         if (role == "() ") role = "";

         if (i.teamSysId == team.sysId)
            result += " - " + role + i.firstName + " " + i.lastName;
         else {
            if (i.team2SysId != null && i.team2SysId == team.sysId) {
               result += " - <span style='font-style:italic;color:#999999'>" + role + i.firstName + " " + i.lastName + "</span>";
            }
         }
      });
      result = result.replace("` - ", "").replace("`", "");
      return result;
   }

   public initialize() {
      this.subjectService.IsTurnOffAdminButtonSubject.subscribe(value => { this.isTurnAdminButtonsOff = value; });

      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public isInformationEditable() {
      return this.accessRightsService.isInformationEditable;
   }

   public onFilterChange(): void {
      this.refresh();
   }

   public refresh() {
      // this.filter = this.cacheService.getAllChampionships().map(x => (
      //    {
      //       value: x.sysId,
      //       viewValue: this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", x)
      //    }));
      this.allTeamsFiltered = this.cacheService.getAllTeams().filter(i => i.division != "" && i.sysId > 1);
   }

   public translatePage() {
      //TeamTitle
      this.translateService.get(['TeamTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['TeamTitle'];
         });
   }
}