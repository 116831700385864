<form [formGroup]="form">

    <mat-card class="mat-card-color popupDimensionTrainingPlayerManager">
        <mat-card-header class="mat-card-header-color">
            <mat-card-title>
                <div>{{title}}</div>
            </mat-card-title>
            <mat-card-subtitle class="changeWidth">
                <div class="flexSpaceBetween">
                    <div>
                        <span class="present legendAvailability">
                            <div class="legendCenter">{{playersPresentTotal}}</div>
                        </span>
                        <span class="guard legendAvailability">
                            <div class="legendCenter">{{playersOnGuardTotal}}</div>
                        </span>
                        <span class="notPresent legendAvailability">
                            <div class="legendCenter">{{playersNotPresentTotal}}</div>
                        </span>
                        <span class="verticalCenter smallFont">
                            ({{ playersOnGuardTotal + playersNotPresentTotal + playersPresentTotal }} joueurs)
                        </span>
                    </div>
                    <span style="margin-left:-20px">
                        <app-button icon="close" [class]="'buttonNormalNo'" [text]="'Close' | translate"
                            [isBackgroundOpaque]="true" (onClick)="onCancel()"></app-button>
                    </span>
                </div>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="mat-card-content-padding">
            <!-- Display all users of the team (without coach) -->
            <ng-container *ngFor="let user of allUsersOfTeamOfCurrentUser; let i = index;"
                #allButtons>
                <div>

                    <span>
                        <span class="reasonWidth space">{{getReasonTranslated(user)}}</span>

                        <app-button icon="expand_more" [class]="allButtonClass[i+allCoachsOfTeamOfCurrentUser.length]"
                            [visibility]="isCoachOrAssistantOrSupervisorConnected"
                            (onClick)="onEditPresence(user, i+allCoachsOfTeamOfCurrentUser.length)"></app-button>

                        <span *ngIf="!isCoachOrAssistantOrSupervisorConnected"
                            [class]="allButtonClass[i+allCoachsOfTeamOfCurrentUser.length]">
                            <span class="material-icons" style="margin-left: auto">{{getPresenceIcon(user)}} </span>
                        </span>&nbsp;

                        <span class="bigFont">{{user.firstName}}</span>
                        <span class="smallFont">{{user.lastName}} {{getJacketNumber(user)}}</span>
                    </span>

                    <div *ngIf="editionIndexVisible == i+allCoachsOfTeamOfCurrentUser.length" class="br0"></div>
                    <div *ngIf="editionIndexVisible == i+allCoachsOfTeamOfCurrentUser.length && getAvailability(user) == EnumAvailability.PRESENT"
                        class="editionPanel">
                        <app-button [icon]="'thumb_down'" [class]="'notPresent'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToNotPresent(user)"></app-button>
                        <mat-select placeholder="(motif)" formControlName="allReasons" class="dropDownTPManagerWidth space">
                            <mat-option *ngFor="let item of allReasons" [value]="item.value">
                                <ng-container>{{item.viewValue}}</ng-container>
                            </mat-option>
                        </mat-select>
                        <app-button [icon]="'thumbs_up_down'" [class]="'guard space'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToGuard(user)"></app-button>
                    </div>

                    <div *ngIf="editionIndexVisible == i+allCoachsOfTeamOfCurrentUser.length && getAvailability(user) == EnumAvailability.GUARD"
                        class="editionPanel">
                        <app-button [icon]="'thumb_down'" [class]="'notPresent'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToNotPresent(user)"></app-button>
                        <mat-select placeholder="(motif)" formControlName="allReasons" class="dropDownTPManagerWidth space">
                            <mat-option *ngFor="let item of allReasons" [value]="item.value">
                                <ng-container>{{item.viewValue}}</ng-container>
                            </mat-option>
                        </mat-select>
                        <app-button [icon]="'thumb_up'" [class]="'present'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToPresent(user)"></app-button>
                    </div>

                    <div *ngIf="editionIndexVisible == i+allCoachsOfTeamOfCurrentUser.length && getAvailability(user) == EnumAvailability.NOTPRESENT"
                        class="editionPanel">
                        <mat-select placeholder="(motif)" formControlName="allReasons" class="dropDownTPManagerWidth space">
                            <mat-option *ngFor="let item of allReasons" [value]="item.value">
                                <ng-container>{{item.viewValue}}</ng-container>
                            </mat-option>
                        </mat-select>
                        <app-button [icon]="'thumb_down'" [class]="'notPresent space'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToNotPresent(user)"></app-button>
                        <app-button [icon]="'thumbs_up_down'" [class]="'guard space'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToGuard(user)"></app-button>
                        <app-button [icon]="'thumb_up'" [class]="'present'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToPresent(user)"></app-button>
                    </div>

                    <div *ngIf="editionIndexVisible == i+allCoachsOfTeamOfCurrentUser.length && getAllAvailability(user).length > 0" class="editionPanel">
                        <ng-container *ngFor="let tp of getAllAvailability(user)">
                            <div class="flexSpaceBetween">
                                <div>{{getCalendarActivityDate(tp.day)}}</div>
                                <div>{{tp.type | translate}} / {{"REASON_" + tp.reason | translate}}</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="br0"></div>
            </ng-container>
            <div class="br0"></div>
            <div class="borderBottom"></div>
            <div class="br0"></div>
            <!-- Display the coach/assistant of the team -->
            <ng-container *ngFor="let user of allCoachsOfTeamOfCurrentUser; let i = index;"
                #allButtons>
                <div>
                    <span>
                        <span class="reasonWidth space">{{getReasonTranslated(user)}}</span>
                        <app-button icon="expand_more" [class]="allButtonClass[i]"
                            [visibility]="isCoachOrAssistantOrSupervisorConnected"
                            (onClick)="onEditPresence(user, i)"></app-button>
                        <span *ngIf="!isCoachOrAssistantOrSupervisorConnected" [class]="allButtonClass[i]">
                            <span class="material-icons" style="margin-left: auto">
                                {{getPresenceIcon(user)}}
                            </span>
                        </span>&nbsp;
                        <span class="bigFont">{{user.firstName}}</span>
                        <span class="smallFont">{{user.lastName}}
                            {{getJacketNumber(user)}}</span>
                    </span>
                    <div *ngIf="editionIndexVisible == i" class="br1"></div>
                    <div *ngIf="editionIndexVisible == i && getAvailability(user) == EnumAvailability.PRESENT"
                        class="editionPanel">
                        <app-button [icon]="'thumb_down'" [class]="'notPresent'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToNotPresent(user)"></app-button>
                        <mat-select placeholder="(motif)" formControlName="allReasons" class="dropDownTPManagerWidth space">
                            <mat-option *ngFor="let item of allReasons" [value]="item.value">
                                <ng-container>{{item.viewValue}}</ng-container>
                            </mat-option>
                        </mat-select>
                        <app-button [icon]="'thumbs_up_down'" [class]="'guard'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToGuard(user)"></app-button>
                    </div>
                    <div *ngIf="editionIndexVisible == i && getAvailability(user) == EnumAvailability.GUARD"
                        class="editionPanel">
                        <app-button [icon]="'thumb_down'" [class]="'notPresent'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToNotPresent(user)"></app-button>
                        <mat-select placeholder="(motif)" formControlName="allReasons" class="dropDownTPManagerWidth space">
                            <mat-option *ngFor="let item of allReasons" [value]="item.value">
                                <ng-container>{{item.viewValue}}</ng-container>
                            </mat-option>
                        </mat-select>
                        <app-button [icon]="'thumb_up'" [class]="'present'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToPresent(user)"></app-button>
                    </div>
                    <div *ngIf="editionIndexVisible == i && getAvailability(user) == EnumAvailability.NOTPRESENT"
                        class="editionPanel">
                        <mat-select placeholder="(motif)" formControlName="allReasons" class="dropDownTPManagerWidth space">
                            <mat-option *ngFor="let item of allReasons" [value]="item.value">
                                <ng-container>{{item.viewValue}}</ng-container>
                            </mat-option>
                        </mat-select>
                        <app-button [icon]="'thumb_down'" [class]="'notPresent space'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToNotPresent(user)"></app-button>
                        <app-button [icon]="'thumbs_up_down'" [class]="'guard space'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToGuard(user)"></app-button>
                        <app-button [icon]="'thumb_up'" [class]="'present'" [isBackgroundOpaque]="true" [text]="''"
                            (onClick)="onChangeToPresent(user)"></app-button>
                    </div>
                    <div *ngIf="editionIndexVisible == i && getAllAvailabilityOfCoach(user).length > 0" class="editionPanel">
                        <ng-container *ngFor="let tp of getAllAvailabilityOfCoach(user)">
                            <div class="flexSpaceBetween">
                                <div>{{getCalendarActivityDate(tp.day)}}</div>
                                <div>{{tp.type | translate}} / {{"REASON_" + tp.reason | translate}}</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </mat-card-content>
        <mat-card-header>
            <mat-card-title>
                <div class="chart-global-wrapper">
                    <canvas baseChart [colors]="chartColors" [data]="pieChartData" [chartType]="pieChartType"
                        [options]="pieChartOptions" [plugins]="pieChartPlugins">
                    </canvas>
                </div>
            </mat-card-title>
        </mat-card-header>
    </mat-card>

</form>